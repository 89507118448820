import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import PortfolioIcon from "../images/joshkirby_portfolio_icon.png"
import useSiteMetadata from "../hooks/use-site-metadata"
import "./styles/header.css"

const Header = ({ siteTitle }) => {
const data = useSiteMetadata()

return (
  <header className="header">
    <nav className="navbar">
      <ul>
        <Link to="/">
          <img src={ PortfolioIcon } className="logo" />
        </Link>
        {/* The following lines are temporary. */}
        <li className="nav-items" key='Code'><Link to='/code/'>Code</Link></li>
        <li className="nav-items" key='Contact'><Link to='/contact/'>Contact</Link></li>

        {/* I'll be using the next chuck for rendering the navbar dynamically in the future. */}
        {/* {
          data.menuLinks.map(link => (
            <li className="nav-items" key={link.name}>
              <Link to={link.link}>
                {link.name}
              </Link>
            </li>
          ))
        } */}
      </ul>
    </nav>
  </header>
)
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}


export default Header
