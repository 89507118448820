/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import useSiteMetadata from "../hooks/use-site-metadata"

import Header from "./header"
import HomeHeader from "./home-header"
import Footer from "./footer"
import "./layout.css"

const Layout = ({ children, location }) => {
  const data = useSiteMetadata()

  // console.log("HREF: " + window.location.href)
  // console.log("Menu Links: " + data.menuLinks)
    // console.log("This is the URL: " + data.siteURL + " and current page: " + this.props.location.pathname)
    
  //The follow line may not build properly.
  // const headerCode = data.menuLinks ? <Header siteTitle={data.title || `Title`} /> : <HomeHeader siteTitle={data.title || `Title`} />
  // const headerCode = <Header siteTitle={data.title || `Title`} />

  return (
    <>
      <div className="background-format dark-theme-text">
      <Header siteTitle={data.title || `Title`} />
        <main> { children } </main>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
